import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './Comman/Home';
import BookAnAppointment from './Book Appointment/bookAppointment';
import SpecialistLocation from './Specialist Location /specialistLocation';
import ChemistLocation from './Chemist Location/chemistLocation';
import AboutBPH from './AboutBPH/aboutBPH';
import TestBph from './TestBPH/testBph';
import NavBarWar from './Comman/NavBarWar';
import Geolocation from './Geolocation.js/Geolocation';


//////////////////   Admin Side ////////////////////
import AdminLogin from './Admin/Auth/AdminLogin';
import AdminDashboard from './Admin/Dashboard/AdminDashboard';
import AdminMRTable from './Admin/User/Component/AdminMRTable';
import AdminAddMR from './Admin/User/Page/AdminAddMR';
import AdminChemistTable from './Admin/User/Component/AdminChemistTable';
import AdminSpecialistTabel from './Admin/User/Component/AdminSpecialistTabel';
import AdminAddChemist from './Admin/User/Page/AdminAddChemist';
import AdminAddSpecialist from './Admin/User/Page/AdminAddSpecialist';
import EditChemist from './Admin/User/Page/EditChemist';
import EditSpecialist from './Admin/User/Page/EditSpecialist';
import EditMR from './Admin/User/Page/EditMR';
import AdminProfile from './Admin/Profile/adminprofile';
import AdminReport from './Admin/User/Component/AdminReport';
import AdminReportDetailView from './Admin/User/Page/AdminChemistReportDetailView';
import AdminDoctorViewDetails from './Admin/User/Page/AdminDoctorViewDetails';


/////////////////////// MR ////////////////
import MRLogin from './MR/auth/MrLogin';
import MrDashboard from './MR/Dashboard/MrDashboard';
import AddChemist from './MR/User/Page.js/AddChemist';
import AddSpecialist from './MR/User/Page.js/AddSpecialist';
import ChemistTabel from './MR/User/Componet/ChemistTabel';
import MrSpecialistTable from './MR/User/Componet/SpecialistTable';
import MrProfile from './MR/Profile/MrProfile';

const currentUserSubject = sessionStorage.getItem('customToken');
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      currentUserSubject ? (
        <Component {...props} />
      ) : (
        <Redirect
          from=''
          to={{
            pathname: ""
          }}
          noThrow
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      currentUserSubject === undefined || currentUserSubject === null ? (
        <Component {...props} />
      ) : (
        <Redirect
          from=''
          to={{
            pathname: "/"
          }}
          noThrow
        />
      )
    }
  />
);



function App() {
  return (
    <div className="App">
      <Geolocation />
      <Router>
        <Switch>
          <PublicRoute exact path='/' component={ChemistLocation} />
          <PublicRoute exact path='/aboutbph' component={AboutBPH} />
          <PublicRoute exact path='/chemistlocator' component={ChemistLocation} />
          <PublicRoute exact path='/specialistlocator' component={SpecialistLocation} />
          <PublicRoute exact path='/bookanappointment' component={BookAnAppointment} />
          <PublicRoute exact path='/testbph' component={TestBph} />

          {/* //////////////////// Admin Side Router /////////////  */}
          <PublicRoute exact path='/admin' component={AdminLogin} />
          <PrivateRoute exact path='/admin/dashboard' component={AdminDashboard} />
          <PrivateRoute exact path='/admin/mr' component={AdminMRTable} />
          <PrivateRoute exact path='/admin/addmr' component={AdminAddMR} />
          <PrivateRoute exact path='/admin/chemist' component={AdminChemistTable} />
          <PrivateRoute exact path='/admin/specialist' component={AdminSpecialistTabel} />
          <PrivateRoute exact path='/admin/addchemist' component={AdminAddChemist} />
          <PrivateRoute exact path='/admin/addspecialist' component={AdminAddSpecialist} />
          <PrivateRoute exact path='/admin/editchemist/:id' component={EditChemist} />
          <PrivateRoute exact path='/admin/editspecialist/:id' component={EditSpecialist} />
          <PrivateRoute exact path='/admin/editmr/:id' component={EditMR} />
          <PrivateRoute exact path='/admin/profile' component={AdminProfile} />
          <PrivateRoute exact path='/admin/report' component={AdminReport} />
          <PrivateRoute exact path='/admin/employee_report_chemist/:id' component={AdminReportDetailView} />
          <PrivateRoute exact path='/admin/employee_report_doctor/:id' component={AdminDoctorViewDetails} />



          {/* //////////////////// Mr Side Router /////////////  */}
          <PublicRoute exact path='/mr' component={MRLogin} />
          <PrivateRoute exact path='/mr/dashboard' component={MrDashboard} />
          <PrivateRoute exact path='/mr/chemist' component={ChemistTabel} />
          <PrivateRoute exact path='/mr/specialist' component={MrSpecialistTable} />
          <PrivateRoute exact path='/mr/addchemist' component={AddChemist} />
          <PrivateRoute exact path='/mr/addspecialist' component={AddSpecialist} />
          <PrivateRoute exact path='/mr/profile/' component={MrProfile} />


        </Switch>
      </Router>
    </div>
  );
}

export default App;
